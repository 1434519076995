import React, { useState } from "react";
import "./index.scss";
import Header from "./pages/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Body from "./pages/Body";
import PrinciplesComponent from "./pages/body/Principles";
import BodyFooter from "./pages/body/BodyFooter";
import Footer from "./pages/body/Footer";

function LoginForAfrica() {
   return (
      <>
         <div className="loginForAfrica">
            <Header />
            <Body />
            <PrinciplesComponent />
            <BodyFooter />
            <Footer />
         </div>
      </>
   );
}

export default LoginForAfrica;
