import React, { useState } from "react";
import "./styles.scss";
import afrikaLogo from "./assets/images/ForAfrika.svg";
import kaporgLogo from "./assets/images/KAPorg.svg";
import imgBackground from "./assets/images/image.png";
import rightArrow from "./assets/images/Frame.svg";

import { Formik } from "formik";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { login } from "../../authSlice";
import BlockUI from "../../../../base/components/BlockUI";
import { EyeInvisible, EyeVisible } from "../../../../assets/icons";
import { Checkbox, Spin } from "antd";

const Header = () => {
   const initialValues = {
      email: "",
      password: ""
   };
   const { isLoading } = useSelector((state) => state.auth);
   const navigateToOrg = useSelector((state) => state.auth.navigateToOrg);
   const navigateToExpert = useSelector((state) => state.auth.navigateToExpert);
   const dispatch = useDispatch();
   const [checked, setChecked] = useState(false);

   const [type, setType] = useState("password");
   const [icon, setIcon] = useState(EyeInvisible);

   const navigate = useNavigate();

   const toggleChecked = () => {
      setChecked(!checked);
   };

   const onChange = (e) => {
      setChecked(e.target.checked);
   };

   const LoginSchema = Yup.object().shape({
      email: Yup.string()
         .email("Invalid email")
         .required("Required"),
      password: Yup.string()
         .max(16, "Maximum 16 symbols")
         .required("Required")
   });

   const handleToggle = () => {
      if (type === "password") {
         setIcon(EyeVisible);
         setType("text");
      } else {
         setIcon(EyeInvisible);
         setType("password");
      }
   };

   const submitForm = (values) => {
      dispatch(login(values, successCallback, errCB));
   };

   const successCallback = () => {
      navigate("/admin/workspace");
   };

   const errCB = (data) => {
      if (data.target?.targetUrl) {
         navigate(data.target?.targetUrl, {
            state: { fromApp: true, data: data.target.params }
         });
      }
   };

   return (
      <div style={{ backgroundColor: "#fff" }}>
         <div className="headerLogo">
            <div className="container">
               <div className="logo d-flex ">
                  <img src={afrikaLogo} alt="forAfrika" />
                  <div className="vertical-line" style={{ padding: "0.1px" }}></div>

                  <img src={kaporgLogo} alt="forAfrika" />
               </div>
            </div>
         </div>

         <div className="hero-bg">
            <div className="img-bg">
               <img src={imgBackground} alt="img-background" />
               <div className="banner-overlay-grey" />
            </div>
            <div className="container">
               <div className="hero-banner-content">
                  <div className="hero-text-africa">
                     <h4 className="m-0">
                        ForAfrika is the largest African humanitarian and development organisation
                        working for an Africa that thrives.
                     </h4>
                     <p className="m-0" style={{ fontWeight: 500 }}>
                        As an African organization, we address the unique challenges of our
                        continent through an African-led approach that focuses on long-term impact
                        because Africa is home, and we don't leave.
                     </p>
                     <a href="https://www.forafrika.org/">
                        <button
                           className="d-flex justify-content-center align-items-center"
                           style={{ padding: "12px 12px 0 0" }}
                        >
                           Learn more
                           <img src={rightArrow} alt="" />
                        </button>
                     </a>
                  </div>
                  <div className="hero-form">
                     <>
                        <div className="image-form">
                           <img src={imgBackground} alt="img-background" />
                           <div className="overlay"></div>
                        </div>
                        {isLoading ? (
                           <BlockUI isLoading={isLoading} />
                        ) : (
                           <div
                              className="pt-md-20 pr-md-10 pb-md-20 pl-md-10 custom-div"
                              style={{
                                 margin: "0 !important",
                                 display: "block",
                                 padding: "60px 40px",
                                 height: "560px",
                                 backgroundColor: "#fff"
                              }}
                           >
                              <div
                                 className="d-flex flex-column"
                                 style={{ gap: "8px", marginBottom: "32px" }}
                              >
                                 <p
                                    style={{
                                       color: "#A50000",
                                       fontWeight: 600,
                                       fontSize: "26px",
                                       margin: 0
                                    }}
                                 >
                                    Welcome back!
                                 </p>
                                 <p
                                    className="m-0"
                                    style={{
                                       fontSize: "14px",
                                       fontWeight: "bold",
                                       color: "#3D4043"
                                    }}
                                 >
                                    Login into your account
                                 </p>
                              </div>
                              <Formik
                                 initialValues={initialValues}
                                 validationSchema={LoginSchema}
                                 onSubmit={(values) => {
                                    submitForm(values);
                                 }}
                              >
                                 {({ handleSubmit, handleChange, values, errors }) => (
                                    <form
                                       onSubmit={handleSubmit}
                                       className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                                    >
                                       <div
                                          style={{
                                             display: "flex",
                                             flexDirection: "column",
                                             gap: "24px"
                                          }}
                                       >
                                          <div className="form-group fv-plugins-icon-container">
                                             <label
                                                style={{
                                                   fontSize: "14px",
                                                   color: "#3A3737",
                                                   fontWeight: "bold",
                                                   marginBottom: "8px"
                                                }}
                                             >
                                                Email
                                             </label>
                                             <input
                                                placeholder="yourmail@gmail.com"
                                                className={`form-control`}
                                                type="email"
                                                name="email"
                                                onChange={handleChange}
                                                values={values.email}
                                             />
                                             {errors.email && (
                                                <div className="error-message">{errors.email}</div>
                                             )}
                                          </div>
                                          <div className="form-group fv-plugins-icon-container">
                                             <label
                                                style={{
                                                   fontSize: "14px",
                                                   color: "#3A3737",
                                                   fontWeight: "bold",
                                                   marginBottom: "8px"
                                                }}
                                             >
                                                Password
                                             </label>
                                             <div className="password-section">
                                                <input
                                                   autoComplete="off"
                                                   placeholder="Password"
                                                   className={`form-control`}
                                                   type={type}
                                                   name="password"
                                                   onChange={handleChange}
                                                   values={values.password}
                                                />
                                                <img
                                                   className="eyeButton"
                                                   onClick={handleToggle}
                                                   src={icon}
                                                   alt="showPasswordIcon"
                                                   style={{ width: "14px" }}
                                                />
                                             </div>

                                             {errors.password && (
                                                <div className="error-message">
                                                   {errors.password}
                                                </div>
                                             )}
                                          </div>
                                       </div>
                                       <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mt-2 remember-me-text">
                                          <div className="d-flex align-items-center">
                                             <Checkbox
                                                checked={checked}
                                                onChange={onChange}
                                                style={{ lineHeight: "0px" }}
                                             />
                                             <p
                                                style={{
                                                   fontSize: "11px",
                                                   color: "#3D4043",
                                                   margin: "0 0 0 8px"
                                                }}
                                             >
                                                Remember me?
                                             </p>
                                          </div>
                                          <Link
                                             to="/auth/forgot-password"
                                             className="password-text"
                                             id="kt_login_forgot"
                                             style={{ fontSize: "12px", margin: "0 10px" }}
                                          >
                                             <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                                          </Link>
                                       </div>
                                       <div className="form-group d-flex flex-wrap justify-content-center align-items-center mt-15">
                                          <button
                                             id="kt_login_signin_submit"
                                             type="submit"
                                             className={`btn sign-btn sign-btn-red`}
                                          >
                                             {isLoading ? <Spin /> : "Log in"}
                                          </button>
                                       </div>
                                    </form>
                                 )}
                              </Formik>
                              <div className="d-flex justify-content-center mt-4">
                                 <p
                                    style={{
                                       color: "#555",
                                       fontWeight: 400,
                                       fontSize: "14px",
                                       margin: "0"
                                    }}
                                 >
                                    Don't have an account yet?{" "}
                                    <Link
                                       to="/auth/registration"
                                       className="ml-2 sign-up-btn"
                                       id="kt_login_signup"
                                    >
                                       Sign up
                                    </Link>
                                 </p>
                              </div>
                           </div>
                        )}
                     </>
                  </div>
               </div>
            </div>
            <div className="form-banner"></div>
         </div>
      </div>
   );
};

export default Header;
