import React from "react";
import "./styles.scss";
import IconGrid from "./body/IconGrid";
import PrinciplesComponent from "./body/Principles";

const Body = () => {
   return (
      <>
         <div className="bodyContent">
            <div className="bodyParagraph">
               <div className="container">
                  <h3>
                     As an African organisation, we address the unique challenges of our continent
                     through an African-led approach that focuses on long-term impact because Africa
                     is home, and we don’t leave.
                  </h3>
               </div>
            </div>

            <div className="secondBodyAfrica">
               <div className="container">
                  <div className="row">
                     <div className="col-md-8 subTitleLeft">
                        <h5>
                           <b>Our approach</b>
                        </h5>
                        <p>
                           We implement integrated, community-led programmes that save lives, build
                           resilience, and enable transformational community development. Over the
                           past four decades we have built strong partnerships with our communities,
                           delving deep into their challenges and aspirations for fair and
                           sustainable growth. Our guiding principle is simple yet profound:
                           progress thrives when it springs from within communities themselves. We
                           work hand in hand, amplifying their existing plans, developing strategies
                           together, and ensuring they have the resources they need to sustainably
                           provide for themselves and thrive.
                        </p>
                        <p className="m-0">
                           Our approach places communities firmly in the driving seat, instilling a
                           sense of ownership and responsibility. We empower communities with the
                           knowledge, skills, and resources to chart their own sustainable path
                           forward, without relying on temporary external aid.
                        </p>
                     </div>
                     <div className="col-md-4 subTitleRight">
                        <h5>
                           <b>Our impact</b>
                        </h5>
                        <div className="divider bg-dark"></div>
                        <div className="d-flex flex-column">
                           <p>People served in 2023</p>
                           <h3>4,221,541</h3>
                        </div>
                        <div className="divider bg-dark"></div>
                        <div>
                           <p>Funds raised in 2023</p>
                           <h3>$65 million</h3>
                        </div>
                        <div className="divider bg-dark"></div>
                        <div>
                           <p>Programme countries</p>
                           <h3>8</h3>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            {/* <div className="container">
  <div className="row">
    <div className="col-md-6">
      <div className="subTitleLeft">
        <h5>Our approach</h5>
        <p>
          We implement integrated, community-led programmes that save lives,
          build resilience, and enable transformational community
          development. Over the past four decades we have built strong
          partnerships with our communities, delving deep into their
          challenges and aspirations for fair and sustainable growth. Our
          guiding principle is simple yet profound: progress thrives when it
          springs from within communities themselves. We work hand in hand,
          amplifying their existing plans, developing strategies together,
          and ensuring they have the resources they need to sustainably
          provide for themselves and thrive.
        </p>
        <p>
          Our approach places communities firmly in the driving seat,
          instilling a sense of ownership and responsibility. We empower
          communities with the knowledge, skills, and resources to chart
          their own sustainable path forward, without relying on temporary
          external aid.
        </p>
      </div>
    </div>
    <div className="col-md-6">
      <div className="subTitleRight">
        <h5>Our impact</h5>
        <div className="divider bg-dark"></div>
        <div className="d-flex flex-column">
          <p>People served in 2023</p>
          <h3>4,221,541</h3>
        </div>
        <div className="divider bg-dark"></div>
        <div>
          <p>Funds raised in 2023</p>
          <h3>$65 million</h3>
        </div>
        <div className="divider bg-dark"></div>
        <div>
          <p>Programme countries</p>
          <h3>8</h3>
        </div>
      </div>
    </div>
  </div>
</div> */}

            <div className="third-body-forafrica">
               <div class="divider "></div>
               <div className="container">
                  <div className="subtitleBottomAfrica">
                     <h5 className="m-0">
                        <b>Our programming pillars</b>
                     </h5>
                     <p>
                        Across our six core programming pillars, developed through tried-and-tested
                        processes, we address a myriad of interconnected community vulnerabilities.
                     </p>
                  </div>
                  <div className="subtitleIcons">
                     <IconGrid />
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Body;
