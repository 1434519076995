import React from "react";
import "./styles.scss"; // Ensure this SCSS file is linked
import childImg from "../assets/images/child.png";

const PrinciplesComponent = () => {
   return (
      <div className="principles-component">
         <div className="container">
            <div className="row g-0">
               <h2 className="principles-title m-0">Principles of ForAfrika's programmes</h2>
               <div className="col-md-6 d-flex justify-content-center align-items-center">
                  <img src={childImg} alt="Child Eating" className="img-fluid" />
               </div>
               <div className="col-md-6 d-flex align-items-center">
                  <div className="text-content">
                     <h2 className="mb-4">Principles of ForAfrika's programmes</h2>
                     <ul className="d-flex flex-column" style={{ margin: "0 10px", gap: "24px" }}>
                        <li>Community-centered and empowering.</li>
                        <li>
                           Integrated, holistic, transformational, and conceptualised and managed as
                           a Programme rather than projects.
                        </li>
                        <li>Seek to build household resilience.</li>
                        <li>
                           Contribute to the global SDGs as well as National Development priorities,
                           as outlined in the National Development Plans for each respective country
                           of operation.
                        </li>
                        <li>
                           Rights-based, inclusive of age-appropriate interventions, gender
                           responsiveness, promoting equality and the empowerment of women, and
                           ensuring inclusion across all criteria of the principle of inclusion.
                        </li>
                        <li>Partnership and localisation</li>
                        <li>Rigorous monitoring and evaluation</li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default PrinciplesComponent;
