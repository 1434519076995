/* eslint-disable */
import React from "react";
import style from "./Footer.module.scss";
import forAfrika from "../assets/images/ForAfrika.svg";
import phoneIcon from "../assets/images/svg/phoneIcon.svg";
import worldIcon from "../assets/images/svg/world.svg";
import linkedinIcon from "../assets/images/svg/brand-linkedin.svg";
import KAPorgLogo from "../assets/images/svg/KAPorg-logo.svg";
import mailIcon from "../assets/images/svg/mailIcon.svg";

const Footer = () => {
   // Function to handle WhatsApp redirect
   const redirectToWhatsApp = (phoneNumber) => {
      // Removing any special characters from phone number
      const cleanNumber = phoneNumber.replace(/[^\d]/g, "");
      return `https://wa.me/${cleanNumber}`;
   };
   return (
      <>
         <div className={style.footerAll} style={{ backgroundColor: "#F3F4F6" }}>
            <div className="container">
               <div className={style.footerSection}>
                  <div className={style.firstRow}>
                     <div>
                        <div className="headerLogo" style={{ padding: 0, background: "#F3F4F6" }}>
                           <div className="container" style={{ padding: 0 }}>
                              <div className="logo d-flex align-items-center">
                                 <img src={forAfrika} alt="ask agency logo" />

                                 <div className="vertical-line"></div>

                                 <img src={KAPorgLogo} alt="KAPorg logo" />
                              </div>
                           </div>
                        </div>
                        <p className={style.p}>
                           KAPorg is a product of
                           <br />
                           <strong>
                              International Advisory, Products and Systems Ltd. (i-APS)
                           </strong>
                        </p>
                     </div>
                  </div>
                  <div className={style.secondPart}>
                     <div className={style.contactSuportBrandsSection}>
                        <div className="d-flex flex-column" style={{ gap: "26px" }}>
                           <h5 className={`${style.h5} mb-0`}>Contact us</h5>
                           <span
                              style={{
                                 fontWeight: "bold",
                                 fontSize: "14px",
                                 color: "#555555"
                              }}
                           >
                              5805 Governors View Lane, Alexandria, Virginia, 22310. United States
                              of America
                           </span>
                        </div>
                        <div className={style.contactContent}>
                           <div className={style.contentt}>
                              {/* <img src={PhoneIcon} alt="phone" /> */}
                              {/* <h7 className={style.h7}>(+1) 202 714 4711</h7> */}
                              <div
                                 className="d-flex align-items-center justify-content-center"
                                 style={{ gap: "8px" }}
                              >
                                 <img src={phoneIcon} alt="phone icon" />
                                 <a
                                    className={style.h7}
                                    href={redirectToWhatsApp("(+1) 202 714 4711")}
                                    target="_blank" // Open in new tab
                                    rel="noopener noreferrer" // Security for opening links in new tab
                                    style={{ textDecoration: "none" }}
                                 >
                                    (+1) 202 714 4711
                                 </a>
                              </div>
                           </div>
                           <div className={style.contentt}>
                              {/* <img src={MailIcon} alt="mail" /> */}
                              <div
                                 className="d-flex align-items-center justify-content-center"
                                 style={{ gap: "8px" }}
                              >
                                 <img src={mailIcon} alt="phone icon" />
                                 <a
                                    className={style.h7}
                                    style={{ textDecoration: "none" }}
                                    href={"mailto:info@kaptechgroup.com "}
                                 >
                                    info@kaptechgroup.com
                                 </a>
                              </div>
                           </div>
                           <div className={style.worldBrand}>
                              <a href="https://panel.kaporg.com/" rel="noopener noreferrer">
                                 <img src={worldIcon} alt="world" />
                              </a>
                              <a
                                 href="https://www.linkedin.com/company/kaporg/"
                                 target="_blank"
                                 rel="noopener noreferrer"
                              >
                                 <img src={linkedinIcon} alt="linkedin" />
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className={style.contactSuportBrandsSection}>
                        <h5 className={style.h5}>Support</h5>
                        <div className={style.supportBrandContent}>
                           <h7 className={style.h7}>
                              <a
                                 style={{
                                    color: "#3A3737",
                                    textDecoration: "none",
                                    whiteSpace: "nowrap"
                                 }}
                                 href={"mailto:info@kaptechgroup.com"}
                              >
                                 Help center
                              </a>
                           </h7>
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  style={{
                     width: "100%",
                     height: "0.2px",
                     backgroundColor: "#000",
                     marginTop: "20px"
                  }}
               ></div>
               <div className={`${style.termsSection} d-flex`} style={{ gap: "30px" }}>
                  <span
                     style={{
                        marginTop: "24px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: 500
                     }}
                  >
                     <a
                        href="https://www.kaporg.com/terms-of-service"
                        target="_blank"
                        style={{ color: "#000" }}
                     >
                        Terms of Service
                     </a>
                  </span>
                  <span
                     style={{
                        marginTop: "24px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: 500
                     }}
                  >
                     <a
                        href="https://www.kaporg.com/privacy-policy"
                        target="_blank"
                        style={{ color: "#000" }}
                     >
                        Privacy Policy
                     </a>
                  </span>
                  <span
                     style={{
                        marginTop: "24px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: 500
                     }}
                  >
                     <a
                        href="https://www.kaporg.com/cookie-policy"
                        target="_blank"
                        style={{ color: "#000" }}
                     >
                        Cookie Policy
                     </a>
                  </span>
               </div>
            </div>
         </div>
      </>
   );
};
export default Footer;
