import React from "react";
import "./styles.scss";

import emergencyLogo from "../assets/images/svg/emergency.svg";
import foodLogo from "../assets/images/svg/food.svg";
import educationLogo from "../assets/images/svg/education.svg";
import waterLogo from "../assets/images/svg/water.svg";
import healthLogo from "../assets/images/svg/health.svg";
import economicLogo from "../assets/images/svg/economic.svg";

const IconGrid = () => {
   return (
      <div className="container icon-grid">
         <div className="row">
            <div
               className="col-md-4 icon-container d-flex align-items-center"
               style={{ gap: "24px" }}
            >
               <div className="icon icon-color-emergency m-0">
                  <img src={emergencyLogo} alt="emergency" style={{ width: "60px" }} />
               </div>
               <div className="icon-label" style={{ color: "#A50000" }}>
                  Emergency Response
               </div>
            </div>
            <div
               className="col-md-4 icon-container d-flex align-items-center"
               style={{ gap: "24px" }}
            >
               <div className="icon icon-color-food m-0">
                  <img src={foodLogo} alt="foodLogo" style={{ width: "60px" }} />
               </div>
               <div className="icon-label d-flex flex-column" style={{ color: "#BE6B04" }}>
                  Food Security <span>and Livelihoods</span>
               </div>
            </div>
            <div
               className="col-md-4 icon-container d-flex align-items-center"
               style={{ gap: "24px" }}
            >
               <div className="icon icon-color-education m-0">
                  <img src={educationLogo} alt="educationLogo" style={{ width: "60px" }} />
               </div>
               <div className="icon-label d-flex flex-column" style={{ color: "#262633" }}>
                  Education
               </div>
            </div>
         </div>
         <div className="row">
            <div
               className="col-md-4 icon-container d-flex align-items-center"
               style={{ gap: "24px" }}
            >
               <div className="icon icon-color-wash m-0">
                  <img src={waterLogo} alt="waterLogo" style={{ width: "60px" }} />
               </div>
               <div className="icon-label d-flex flex-column" style={{ color: "#003640" }}>
                  Water, Sanitation <span> and Hygiene (WASH)</span>
               </div>
            </div>
            <div
               className="col-md-4 icon-container d-flex align-items-center"
               style={{ gap: "24px" }}
            >
               <div className="icon icon-color-health m-0">
                  <img src={healthLogo} alt="healthLogo" style={{ width: "60px" }} />
               </div>
               <div className="icon-label" style={{ color: "#63B79B" }}>
                  Health and Nutrition
               </div>
            </div>
            <div
               className="col-md-4 icon-container d-flex align-items-center"
               style={{ gap: "24px" }}
            >
               <div className="icon icon-color-economic m-0">
                  <img src={economicLogo} alt="economicLogo" style={{ width: "60px" }} />
               </div>
               <div className="icon-label d-flex flex-column" style={{ color: "#D88E05" }}>
                  Economic <span>empowerment</span>
               </div>
            </div>
         </div>
      </div>
   );
};

export default IconGrid;
