import React, { useState } from "react";
import "./styles.scss";
import footerImg1 from "../assets/images/footerImg1.png";
import plusVector from "../assets/images/svg/plusVector.svg";
import minusVector from "../assets/images/svg/minusVector.svg";
import footerImage from "../assets/images/footerImage.png";

const BodyFooter = () => {
   const [activeSectionMain, setActiveSectionMain] = useState([]);
   const [activeSectionFooter, setActiveSectionFooter] = useState([]);

   const accordionData = [
      {
         title: "Our history",
         content:
            "Our story began in 1984 with the delivery of three truckloads carrying vital assistance to the famine-torn village of Pambarra in Mozambique."
      },
      {
         title: "Our vision",
         content: "For Africa to Thrive."
      },
      {
         title: "Our purpose",
         content:
            "Driven by a deep love for our people, we won’t stop working until everyone on our continent has the resources they need to sustainably provide for themselves and thrive. "
      },
      {
         title: "Our mission",
         content:
            "To provide the resources that unlock the abundance of Africa so every African community can thrive."
      },
      {
         title: "Where we work",
         content:
            "We operate in Angola, Central African Republic, Ethiopia, Mozambique, Rwanda, South Africa, South Sudan and Uganda."
      }
   ];

   const accordionDataFooter = [
      {
         title: "African expertise and understanding ",
         content:
            "As an African organisation, we possess an inherent understanding of the continent’s diverse contexts, cultures, and challenges. This deep-rooted familiarity allows us to tailor our interventions effectively, ensuring they resonate with local needs and aspirations. "
      },
      {
         title: "Established presence in Africa ",
         content:
            "ForAfrika has the largest footprint of any African Humanitarian and Development Organisation, with over 40 years of active engagement across the continent."
      },
      {
         title: "Community integration ",
         content:
            "We are deeply ingrained within the communities we serve, earning their trust through decades of dedicated service, expertise, and respectful partnership. "
      },
      {
         title: "Proven credibility ",
         content:
            "Our track record of competence, respect for human rights, and diligent collaboration with local communities has established ForAfrika as a trusted and credible entity in the region. "
      },
      {
         title: "Emergency preparedness ",
         content:
            "We are always ready for quick deployment and effective response to humanitarian crises, leveraging our presence within communities to save lives and alleviate suffering promptly. "
      },
      {
         title: "Comprehensive support ",
         content:
            "From life-saving interventions like WASH facilities and emergency provisions to long-term recovery efforts, ForAfrika remains committed to supporting communities throughout every stage of development,  tapping into local resources and skills for sustainable impact. "
      }
   ];

   const toggleAccordionMain = (index) => {
      if (activeSectionMain.includes(index)) {
         setActiveSectionMain(activeSectionMain.filter((item) => item !== index));
      } else {
         setActiveSectionMain([...activeSectionMain, index]);
      }
   };

   const toggleAccordionFooter = (index) => {
      if (activeSectionFooter.includes(index)) {
         setActiveSectionFooter(activeSectionFooter.filter((item) => item !== index));
      } else {
         setActiveSectionFooter([...activeSectionFooter, index]);
      }
   };

   return (
      <>
         <div className="container py-md-5 bodyfooter" style={{ paddingTop: "20px" }}>
            <h5>
               Our goal is to empower 20 million Africans to sustainably provide for themselves by
               2032.{" "}
            </h5>
            <div className="row">
               <div className="col-lg-6">
                  <div className="accordion">
                     {accordionData.map((item, index) => (
                        <div key={index} className="accordion-item">
                           <button
                              className={`${activeSectionMain.includes(index) ? "active" : ""}`}
                              onClick={() => toggleAccordionMain(index)}
                           >
                              {activeSectionMain.includes(index) ? (
                                 <img src={minusVector} alt="minus" />
                              ) : (
                                 <img src={plusVector} alt="plus" />
                              )}
                              {item.title}
                           </button>
                           {activeSectionMain.includes(index) && (
                              <p className="accordion-content">{item.content}</p>
                           )}
                        </div>
                     ))}
                  </div>
               </div>
               <div className="col-lg-6">
                  <img src={footerImg1} alt="Descriptive Alt Text" className="img-fluid" />
               </div>
            </div>
         </div>

         <div className="container last-part">
            <div className="divider"></div>
            <h5>What sets us apart</h5>
            <div className="row">
               <div className="col-lg-6">
                  <img src={footerImage} alt="footerImage" className="img-fluid" />
               </div>
               <div className="col-lg-6">
                  <div className="accordion">
                     {accordionDataFooter.map((item, index) => (
                        <div key={index} className="accordion-item">
                           <button
                              className={`${activeSectionFooter.includes(index) ? "active" : ""}`}
                              onClick={() => toggleAccordionFooter(index)}
                           >
                              {activeSectionFooter.includes(index) ? (
                                 <img src={minusVector} alt="minus" />
                              ) : (
                                 <img src={plusVector} alt="plus" />
                              )}
                              {item.title}
                           </button>
                           {activeSectionFooter.includes(index) && (
                              <p className="accordion-content">{item.content}</p>
                           )}
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default BodyFooter;
